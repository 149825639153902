var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "van-main" },
      [
        _c(
          "van-form",
          {
            ref: "administrators",
            staticClass: "van-form-margin",
            attrs: { "label-width": "120px" }
          },
          [
            _c("p", { staticClass: "van-block-title" }, [
              _vm._v(" 选择管理员身份 ")
            ]),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-radio-group",
                  {
                    staticClass: "van-common-radio-group",
                    model: {
                      value: _vm.appForm.userType,
                      callback: function($$v) {
                        _vm.$set(_vm.appForm, "userType", $$v)
                      },
                      expression: "appForm.userType"
                    }
                  },
                  [
                    _c("van-radio", { attrs: { name: 2 } }, [
                      _vm._v(" 我是法定代表人 ")
                    ]),
                    _c("van-radio", { attrs: { name: 1 } }, [
                      _vm._v(" 我是企业授权委托人 ")
                    ])
                  ],
                  1
                ),
                _vm.appForm.userType === 1
                  ? _c(
                      "div",
                      { staticClass: "van-file" },
                      [
                        _c("upload-file", {
                          attrs: {
                            "rectangle-style": "",
                            "max-count": "1",
                            "upload-text": "上传授权委托书",
                            query: _vm.query,
                            "before-read": _vm.beforeUpload
                          },
                          on: {
                            fileUploadSuccess: function(
                              response,
                              file,
                              fileList
                            ) {
                              return _vm.fileUploadSuccess(
                                "attachments",
                                response,
                                file,
                                fileList
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.appForm.userType === 1
                  ? _c("div", { staticClass: "van-common-title" }, [
                      _vm._v(" 温馨提示：您需点击下载"),
                      _c("a", [_vm._v("《授权委托书》模板")]),
                      _vm._v(
                        "，将模板中的内容复制自行打印，盖章后上传,可发送到下方邮箱地址 "
                      )
                    ])
                  : _vm._e(),
                _vm.appForm.userType === 1
                  ? _c("van-field", {
                      attrs: {
                        center: "",
                        clearable: "",
                        label: "邮箱地址",
                        placeholder: "请输入邮箱地址"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button",
                            fn: function() {
                              return [
                                _c(
                                  "van-button",
                                  {
                                    attrs: {
                                      size: "small",
                                      type: "primary",
                                      "native-type": "button"
                                    },
                                    on: { click: _vm.sendEmail }
                                  },
                                  [_vm._v(" 发 送 ")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1353064283
                      ),
                      model: {
                        value: _vm.email,
                        callback: function($$v) {
                          _vm.email = $$v
                        },
                        expression: "email"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("p", { staticClass: "van-block-title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.appForm.userType === 1
                      ? "选择上传管理员身份证照片"
                      : "请确认以下信息无误"
                  ) +
                  " "
              )
            ]),
            _vm.appForm.userType === 2
              ? _c(
                  "div",
                  { staticClass: "van-common-detail" },
                  [
                    _c(
                      "van-cell-group",
                      [
                        _c("van-cell", {
                          attrs: {
                            title: "姓名：",
                            value: _vm.utils.isEffectiveCommon(
                              _vm.appForm.businessName
                            )
                          }
                        }),
                        _c("van-cell", {
                          attrs: {
                            title: "证件类型：",
                            value: _vm.utils.statusFormat(
                              _vm.appForm.businessSnType,
                              "typeCertificateType"
                            )
                          }
                        }),
                        _c("van-cell", {
                          attrs: {
                            title: "证件号码：",
                            value: _vm.utils.isEffectiveCommon(
                              _vm.appForm.businessSn
                            )
                          }
                        }),
                        _c("van-cell", {
                          attrs: {
                            title: "电子邮箱：",
                            value: _vm.utils.isEffectiveCommon(
                              _vm.appForm.businessEmail
                            )
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "van-file" },
                      [
                        _c("van-image", {
                          attrs: { src: _vm.appForm.businessSnPreImgs }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "van-file" },
                      [
                        _c("van-image", {
                          attrs: { src: _vm.appForm.businessSnBackImgs }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.appForm.userType === 1
              ? _c(
                  "div",
                  { staticClass: "van-common-detail" },
                  [
                    _c(
                      "van-cell-group",
                      [
                        _c("van-field", {
                          attrs: {
                            name: "userName ",
                            label: "姓名：",
                            required: "",
                            placeholder: "请输入姓名",
                            rules: _vm.rules.userName
                          },
                          model: {
                            value: _vm.appForm.userName,
                            callback: function($$v) {
                              _vm.$set(_vm.appForm, "userName", $$v)
                            },
                            expression: "appForm.userName"
                          }
                        }),
                        _c("van-field", {
                          attrs: {
                            readonly: "",
                            clickable: "",
                            required: "",
                            name: "picker",
                            value: _vm.appForm.snType
                              ? _vm.utils.statusFormat(
                                  _vm.appForm.snType,
                                  "typeCertificateType"
                                )
                              : "",
                            label: "证件类型",
                            rules: _vm.rules.snType,
                            placeholder: "请选择证件类型"
                          },
                          on: {
                            click: function($event) {
                              _vm.showPicker = true
                            }
                          }
                        }),
                        _c(
                          "van-popup",
                          {
                            attrs: { position: "bottom" },
                            model: {
                              value: _vm.showPicker,
                              callback: function($$v) {
                                _vm.showPicker = $$v
                              },
                              expression: "showPicker"
                            }
                          },
                          [
                            _c("van-picker", {
                              attrs: {
                                "show-toolbar": "",
                                columns: _vm.constants.typeCertificateType,
                                "value-key": "label",
                                "default-index": _vm._.toNumber(
                                  _vm.appForm.snType
                                )
                              },
                              on: {
                                confirm: _vm.onConfirm,
                                cancel: function($event) {
                                  _vm.showPicker = false
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c("van-field", {
                          attrs: {
                            name: "sn",
                            label: "证件号码：",
                            required: "",
                            placeholder: "请输入证件号码",
                            rules: _vm.rules.sn
                          },
                          model: {
                            value: _vm.appForm.sn,
                            callback: function($$v) {
                              _vm.$set(_vm.appForm, "sn", $$v)
                            },
                            expression: "appForm.sn"
                          }
                        }),
                        _c("van-field", {
                          attrs: {
                            name: "email",
                            label: "电子邮箱：",
                            required: "",
                            placeholder: "请输入电子邮箱",
                            rules: _vm.rules.email
                          },
                          model: {
                            value: _vm.appForm.email,
                            callback: function($$v) {
                              _vm.$set(_vm.appForm, "email", $$v)
                            },
                            expression: "appForm.email"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "van-file" },
                      [
                        _vm.appForm.userType === 1
                          ? _c("upload-file", {
                              attrs: {
                                "rectangle-style": "",
                                "max-count": "1",
                                query: _vm.query,
                                "upload-text": "上传身份证国徽面",
                                "before-read": _vm.beforeUpload
                              },
                              on: {
                                fileUploadSuccess: function(
                                  response,
                                  file,
                                  fileList
                                ) {
                                  return _vm.fileUploadSuccess(
                                    "snPreImgs",
                                    response,
                                    file,
                                    fileList
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "van-file" },
                      [
                        _vm.appForm.userType === 1
                          ? _c("upload-file", {
                              attrs: {
                                "rectangle-style": "",
                                "max-count": "1",
                                query: _vm.query,
                                "upload-text": "上传身份证头像面",
                                "before-read": _vm.beforeUpload
                              },
                              on: {
                                fileUploadSuccess: function(
                                  response,
                                  file,
                                  fileList
                                ) {
                                  return _vm.fileUploadSuccess(
                                    "snBackImgs",
                                    response,
                                    file,
                                    fileList
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "button-fixed" },
              [
                _c(
                  "van-button",
                  {
                    attrs: { block: "", type: "info" },
                    on: { click: _vm.confirm }
                  },
                  [_vm._v(" 身份认证 ")]
                ),
                _c(
                  "van-dialog",
                  {
                    attrs: {
                      title: "信息确认",
                      "show-cancel-button": false,
                      "show-confirm-button": false,
                      "lazy-render": false
                    },
                    model: {
                      value: _vm.dialog.confirm,
                      callback: function($$v) {
                        _vm.$set(_vm.dialog, "confirm", $$v)
                      },
                      expression: "dialog.confirm"
                    }
                  },
                  [
                    _c(
                      "van-cell-group",
                      [
                        _c("van-cell", {
                          attrs: {
                            title: "姓名：",
                            value: _vm.utils.isEffectiveCommon(
                              _vm.confirmForm.name
                            )
                          }
                        }),
                        _c("van-cell", {
                          attrs: {
                            title: "身份证号：",
                            value: _vm.utils.isEffectiveCommon(
                              _vm.confirmForm.idCardNo
                            )
                          }
                        }),
                        _c("van-cell", {
                          attrs: {
                            title: "有效期：",
                            value: _vm.utils.isEffectiveCommon(
                              _vm.confirmForm.signDateStr +
                                "-" +
                                _vm.confirmForm.expirationDateStr
                            )
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "van-button",
                      {
                        staticClass: "dialog-button",
                        attrs: { block: "", type: "info" },
                        on: { click: _vm.checkIdCard }
                      },
                      [_vm._v(" 确认无误 ")]
                    ),
                    _c(
                      "van-button",
                      {
                        staticClass: "dialog-button",
                        attrs: { block: "", type: "info", plain: "" },
                        on: {
                          click: function($event) {
                            _vm.dialog.confirm = false
                          }
                        }
                      },
                      [_vm._v(" 信息有误，重新识别 ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }