<template>
  <div>
    <div class="van-main">
      <van-form ref="administrators" label-width="120px" class="van-form-margin">
        <p class="van-block-title">
          选择管理员身份
        </p>
        <div class="van-common-detail">
          <van-radio-group v-model="appForm.userType" class="van-common-radio-group">
            <van-radio :name="2">
              我是法定代表人
            </van-radio>
            <van-radio :name="1">
              我是企业授权委托人
            </van-radio>
          </van-radio-group>
          <div v-if="appForm.userType===1" class="van-file">
            <upload-file rectangle-style max-count="1" upload-text="上传授权委托书" :query="query" :before-read="beforeUpload" @fileUploadSuccess="(response, file, fileList) => fileUploadSuccess('attachments', response, file, fileList)" />
          </div>
          <div v-if="appForm.userType===1" class="van-common-title">
            温馨提示：您需点击下载<a>《授权委托书》模板</a>，将模板中的内容复制自行打印，盖章后上传,可发送到下方邮箱地址
          </div>
          <van-field
            v-if="appForm.userType===1"
            v-model="email"
            center
            clearable
            label="邮箱地址"
            placeholder="请输入邮箱地址"
          >
            <template #button>
              <van-button size="small" type="primary" native-type="button" @click="sendEmail">
                发  送
              </van-button>
            </template>
          </van-field>
        </div>
        <p class="van-block-title">
          {{ appForm.userType===1? '选择上传管理员身份证照片':'请确认以下信息无误' }}
        </p>
        <div v-if="appForm.userType===2" class="van-common-detail">
          <van-cell-group>
            <van-cell title="姓名：" :value="utils.isEffectiveCommon(appForm.businessName)" />
            <van-cell title="证件类型：" :value="utils.statusFormat(appForm.businessSnType,'typeCertificateType')" />
            <van-cell title="证件号码：" :value="utils.isEffectiveCommon(appForm.businessSn)" />
            <van-cell title="电子邮箱：" :value="utils.isEffectiveCommon(appForm.businessEmail)" />
          </van-cell-group>
          <div class="van-file">
            <van-image
              :src="appForm.businessSnPreImgs"
            />
          </div>
          <div class="van-file">
            <van-image
              :src="appForm.businessSnBackImgs"
            />
          </div>
        </div>
        <div v-if="appForm.userType===1" class="van-common-detail">
          <van-cell-group>
            <van-field
              v-model="appForm.userName"
              name="userName "
              label="姓名："
              required
              placeholder="请输入姓名"
              :rules="rules.userName"
            />
            <van-field
              readonly
              clickable
              required
              name="picker"
              :value="appForm.snType?utils.statusFormat(appForm.snType,'typeCertificateType'):''"
              label="证件类型"
              :rules="rules.snType"
              placeholder="请选择证件类型"
              @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-picker
                show-toolbar
                :columns="constants.typeCertificateType"
                value-key="label"
                :default-index="_.toNumber(appForm.snType)"
                @confirm="onConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
            <van-field
              v-model="appForm.sn"
              name="sn"
              label="证件号码："
              required
              placeholder="请输入证件号码"
              :rules="rules.sn"
            />
            <van-field
              v-model="appForm.email"
              name="email"
              label="电子邮箱："
              required
              placeholder="请输入电子邮箱"
              :rules="rules.email"
            />
          </van-cell-group>
          <div class="van-file">
            <upload-file v-if="appForm.userType===1" rectangle-style max-count="1" :query="query" upload-text="上传身份证国徽面" :before-read="beforeUpload" @fileUploadSuccess="(response, file, fileList) => fileUploadSuccess('snPreImgs', response, file, fileList)" />
          </div>
          <div class="van-file">
            <upload-file v-if="appForm.userType===1" rectangle-style max-count="1" :query="query" upload-text="上传身份证头像面" :before-read="beforeUpload" @fileUploadSuccess="(response, file, fileList) => fileUploadSuccess('snBackImgs', response, file, fileList)" />
          </div>
        </div>
        <div class="button-fixed">
          <van-button block type="info" @click="confirm">
            身份认证
          </van-button>
          <van-dialog v-model="dialog.confirm" title="信息确认" :show-cancel-button="false" :show-confirm-button="false" :lazy-render="false">
            <van-cell-group>
              <van-cell title="姓名：" :value="utils.isEffectiveCommon(confirmForm.name)" />
              <van-cell title="身份证号：" :value="utils.isEffectiveCommon(confirmForm.idCardNo)" />
              <van-cell title="有效期：" :value="utils.isEffectiveCommon(`${confirmForm.signDateStr}-${confirmForm.expirationDateStr}`)" />
            </van-cell-group>
            <van-button block type="info" class="dialog-button" @click="checkIdCard">
              确认无误
            </van-button>
            <van-button block type="info" class="dialog-button" plain @click="dialog.confirm=false">
              信息有误，重新识别
            </van-button>
          </van-dialog>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import UploadFile from '@/components/upload-file'
import { List, PullRefresh, Cell, CellGroup, Search, Button, Form, Field, Calendar, Uploader, Picker, Popup, Sticky, Toast, RadioGroup, Radio, Dialog, Image as VanImage } from 'vant'
export default {
  components: {
    UploadFile,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Form.name]: Form,
    [Field.name]: Field,
    [Calendar.name]: Calendar,
    [Uploader.name]: Uploader,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Sticky.name]: Sticky,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [VanImage.name]: VanImage,
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      enterpriseId: this.$route.query.enterpriseId,
      fileList: [],
      query: {
        module: 'SIGN_UP',
        businessId: this.$route.query.enterpriseId
      },
      appForm: {
        userType: 2,
        businessName: '',
        businessSnType: '',
        businessSn: '',
        businessEmail: '',
        businessSnPreImgs: '',
        businessSnBackImgs: '',
        snPreImgs: '',
        snBackImgs: '',
        userName: '',
        snType: '',
        sn: '',
        email: ''
      },
      confirmForm: {
        name: '',
        idCardNo: '',
        signDateStr: '',
        expirationDateStr: ''
      },
      email: '',
      attachments: '',
      showPicker: false,
      finished: false,
      refreshing: false,
      loading: {
        submit: false
      },
      dialog: {
        confirm: false
      },
      rules: {
        userName: [
          { required: true, message: '请输入姓名' },
          { pattern: this.constants.rulesExclude50, message: this.constants.rulesToolkit50 }
        ],
        snType: [
          { required: true, message: '请选择证件类型', trigger: 'onChange' }
        ],
        sn: [
          { required: true, message: '请输入证件号码' },
          { pattern: this.constants.rulesNumber, message: this.constants.rulesToolkitsNumber },
          { pattern: this.constants.rulesExclude20, message: this.constants.rulesToolkit20 }
        ],
        email: [
          { required: true, message: '请输入电子邮箱' },
          { pattern: this.constants.email, message: this.constants.emailToolkit },
          { pattern: this.constants.rulesExclude50, message: this.constants.rulesToolkit50 }
        ]
      }
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    // 上传大小
    beforeUpload (file) {
      const isLt = file.size / 1024 / 1024 < 10
      if (!isLt) {
        Toast.fail('上传文件不能大于10M')
        return isLt
      } else {
        return isLt
      }
    },
    // 上传成功
    fileUploadSuccess (type, response, file, fileList) {
      if (type === 'attachments') {
        this[type] = response.fileUrl
      } else {
        this.appForm[type] = response.fileUrl
      }
    },
    onConfirm (value) {
      this.appForm.snType = value.value
      this.showPicker = false
    },
    // 获取详情
    getDetail () {
      this.loading.detail = true
      this.api.base.enterpriseAuthAdmin(this.enterpriseId).then(result => {
        const data = this._.cloneDeep(result.data.value)
        data.userType = 2
        this.appForm = this._.assign(this.appForm, data)
      }).finally(() => {
        this.loading.detail = false
      })
    },
    // 发送邮件
    sendEmail () {
      if (!this.email) {
        Toast.fail('请输入邮箱地址')
        return false
      }
      if (!this.constants.email.test(this.email)) {
        Toast.fail('请输入正确的邮箱')
        return false
      }
      if (!this.attachments) {
        Toast.fail('请上传授权委托书')
        return false
      }
      const data = {
        attachments: []
      }
      data.to = this.email
      data.attachments.push(this.attachments)
      data.body = '《授权委托书》模板 附件'
      data.subject = '《授权委托书》模板'
      this.api.base.sendEmail(data).then(result => {
        // const data = result.data.data
        Toast.success(result.data.message || '发送成功')
      }).finally(() => {
        this.loading.submit = false
      })
    },
    confirm () {
      if (this.appForm.userType === 1) {
        if (!this.appForm.snPreImgs) {
          Toast.fail('请上传身份证国徽面')
          return false
        }
        if (!this.appForm.snBackImgs) {
          Toast.fail('请上传身份证头像面')
          return false
        }
      }
      this.$refs.administrators.validate().then(() => {
        // 身份解析
        this.parseIdCardByUrl()
      })
    },
    // 身份解析
    parseIdCardByUrl () {
      const data = {}
      data.snBackImgs = this.appForm.userType === 2 ? this.appForm.businessSnPreImgs : this.appForm.snPreImgs
      data.snFrontImgs = this.appForm.userType === 2 ? this.appForm.businessSnBackImgs : this.appForm.snBackImgs
      this.api.base.parseIdCardByUrl(data).then(result => {
        const data = this._.cloneDeep(result.data.value)
        this.confirmForm = this._.assign(this.confirmForm, data)
        this.dialog.confirm = true
      }).finally(() => {
      })
    },
    // 根据照片识别身份 二要素判断
    checkIdCard () {
      const data = {}
      data.snFrontImgs = this.appForm.userType === 2 ? this.appForm.businessSnBackImgs : this.appForm.snBackImgs
      this.api.base.checkIdCard(data).then(result => {
        Toast.success(result.data.message || '验证成功')
        this.submit()
      }).finally(() => {
      })
    },
    // 提交管理员信息
    submit () {
      const data = this._.cloneDeep(this.appForm)
      if (!data.sn) {
        data.sn = data.businessSn
      }
      this.loading.submit = true
      this.api.base.submitAuthAdmin(data).then(result => {
        // 跳转共同人脸识别
        const data = {
          identityCard: this.appForm.userType === 2 ? this.appForm.businessSn : this.appForm.sn,
          name: this.appForm.userType === 2 ? this.appForm.businessName : this.appForm.userName,
          redirectUrl: window.location.protocol + '//' + window.location.host + '/personal/information/attestationSuccess'
        }
        this.utils.faceValidate(data)
      }).finally(() => {
        this.loading.submit = false
      })
    }
  }
}
</script>

<style scoped lang="less">
</style>
